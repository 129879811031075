import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import FeaturesList from "../components/featuresList"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Divider from "../components/divider"
import SingleQuote from "../components/singleQuote"
import FourNoImageSection from "../components/fourNoImageSection"
import {
  PhoneIcon,
  BookOpenIcon,
  PencilIcon,
  RefreshIcon
} from "@heroicons/react/outline"

import CTABooking from "../components/CTAbooking"

function FreeEBookPage({ data }) {
  const image = getImage(data?.beachLook)
  const quoteImg = getImage(data?.quoteImg)
  const programCTA = getImage(data?.programCTA)

  return (
    <Layout>
      <SEO
        keywords={[
          `flow freedom program`,
          `sincerely eden`,
          `one on one couching`,
          `Fix Period struggles`,
          "Fix Mental Health",
          "Fix Fatigue",
          "Fix Perdiod pain"
        ]}
        title="Sincerely Eden | The Flow Freedom Program"
        description="One on one coaching with Eden Sallenbach. Free discovery calls. "
      />
      <div>
        <Hero
          quote=""
          ctaBtnText={"Start now"}
          titleStart={<>The </>}
          titleEnd="Flow Freedom Program"
          subtitle={
            <div className="tracking-widest font-extralight">
              One on one coaching with Eden Sallenbach
            </div>
          }
          image={image}
        />
        <Divider />
        <FeaturesList
          title=""
          subtitle={
            <>
              Is the{" "}
              <span className="text-sea-pink-700">Flow Freedom Program</span>{" "}
              right for you?
            </>
          }
          subtitle2="If you experience any of these symptoms, there’s a solution."
          features={[
            {
              name: "Period struggles",
              description:
                "Your periods are either missing or heavier than ever"
            },
            {
              name: "Mental Health",
              description:
                "You struggle with mood swings, depression +/or anxiety"
            },
            {
              name: "Pain",
              description:
                "You experience unbearable cramps and breast tenderness every cycle"
            },
            {
              name: "Acne",
              description: "You suffer from ongoing skin breakouts"
            },
            {
              name: "Cravings",
              description: "You crave sugar/processed foods + caffeine"
            },
            {
              name: "Fatigue",
              description: "You feel chronically fatigue"
            },
            {
              name: "Weight Retention",
              description: "You can’t lose weight"
            },
            {
              name: "Low Libido",
              description: "You have zero sex drive"
            }
          ]}
        />
        <Divider />
        <SingleQuote
          img={quoteImg}
          quote="I created the Flow Freedom Program to help you understand how everything you do in your life is connected to the state of your hormones. I help you save time, money, and stress by ensuring your protocol is personalized and flexible, and that your freedom wins are long-lasting."
        />
        <Divider />
        <FourNoImageSection
          title="What's included"
          subtitle="Investing in your health will get you:"
          description="6 months of ongoing support with Eden Sallenbach"
          features={[
            {
              name: "Consults",
              description:
                "12 virtual 1:1 sessions (consistently every 2 weeks).",
              icon: PhoneIcon
            },
            {
              name: "Resources",
              description:
                "Handouts with personalized assignments, dietary + lifestyle recommendations, recipes, and more.",
              icon: BookOpenIcon
            },
            {
              name: "Customization",
              description:
                "Regular plan modifications to allow for growth, as you and your lifestyle evolve.",
              icon: PencilIcon
            },
            {
              name: "Extras",
              description:
                "Optional pantry overhauls + supermarket tours (for local clients only).",
              icon: RefreshIcon
            }
          ]}
        />
        <Divider />
      </div>
      <CTABooking programCTA={programCTA} />
    </Layout>
  )
}

export default FreeEBookPage

export const query = graphql`
  query FFPQuery {
    quoteImg: file(relativePath: { eq: "zoomed-about-me.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          height: 650
          quality: 100
          transformOptions: { cropFocus: NORTHWEST }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    beachLook: file(relativePath: { eq: "smile-white.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
          height: 700
          quality: 100
          placeholder: BLURRED
          transformOptions: { cropFocus: NORTH }
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    programCTA: file(relativePath: { eq: "sitting-white.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
