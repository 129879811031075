/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import { CheckIcon } from "@heroicons/react/outline"

const defaultFeatures = [
  {
    name: "Invite team members",
    description:
      "You can manage phone, email and chat conversations all from a single mailbox."
  },
  {
    name: "List view",
    description:
      "You can manage phone, email and chat conversations all from a single mailbox."
  },
  {
    name: "Keyboard shortcuts",
    description:
      "You can manage phone, email and chat conversations all from a single mailbox."
  },
  {
    name: "Calendars",
    description:
      "You can manage phone, email and chat conversations all from a single mailbox."
  },
  {
    name: "Notifications",
    description:
      "Find what you need with advanced filters, bulk actions, and quick views."
  },
  {
    name: "Boards",
    description:
      "Find what you need with advanced filters, bulk actions, and quick views."
  },
  {
    name: "Reporting",
    description:
      "Find what you need with advanced filters, bulk actions, and quick views."
  },
  {
    name: "Mobile app",
    description:
      "Find what you need with advanced filters, bulk actions, and quick views."
  }
]

export default function Example({
  title = "title 1",
  subtitle = "subtitle 1",
  subtitle2 = "subtitle 2",
  features = defaultFeatures
}) {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <p className="text-base font-semibold text-sea-pink-700 tracking-wide uppercase">
            {title}
          </p>
          <h3 className="mt-2 text-3xl font-extrabold text-gray-900">
            {subtitle}
          </h3>
          <p className="mt-4 text-lg text-gray-500">{subtitle2}</p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {features.map(feature => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-6 w-6 text-porsche-700"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
